<template>
  <div class="container-fluid">
    <div class="d-flex mb-5">
      <AppSearch title="圖片說明" @searchItem="(val) => querySearch(val)" />
      <button
        class="btn btn-primary btn-sm ms-auto"
        type="button"
        @click="openModal('showModifyModal', {})"
      >
        <i class="bi bi-plus-square-fill" style="top: -3px"></i>
        新增輪播圖片
      </button>
    </div>

    <div class="d-sm-flex align-items-center">
      <div
        v-dropdown="{ id: 'publicTypes' }"
        style="width: 128px"
        class="dropdown border border-primary cursor-pointer d-inline-block"
      >
        <div class="text-primary fs-8 d-flex align-items-center justify-content-between px-2">
          <p>圖片: {{ publicTypeName }}</p>
          <i class="ps-2 pb-1 bi bi-caret-down-fill"></i>
        </div>
        <ul class="dropdown-menu" id="publicTypes">
          <li
            v-for="(item, idx) in publicTypes"
            class="px-3 py-1 cursor-pointer"
            :key="`publicTypes${idx}`"
            @click="searchItems.isPublic = item.value"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <p v-if="searchItems.memo" class="px-2 fs-9 text-info">
        搜尋/{{ searchItems.memo }}
        <i class="bi bi-x fs-9 cursor-pointer" @click="handleMemoSearch"></i>
      </p>
    </div>

    <div class="py-3">
      <div class="row mb-2">
        <template v-if="banners.length > 0">
          <div
            class="col-6 col-md-4 col-lg-3 mb-3"
            v-for="item in banners"
            :key="`${item.bannerId}banners`"
          >
            <div class="h-100 card">
              <div class="position-relative">
                <div class="position-absolute top-0 start-0" style="z-index: 2">
                  <span
                    class="badge rounded-pill m-1"
                    :class="item.isPublic ? 'bg-success' : 'bg-danger'"
                    >{{ item.isPublic ? "V 已公開" : "X 未公開" }}</span
                  >
                </div>
              </div>
              <div class="position-relative">
                <div class="position-absolute top-0 end-0" style="z-index: 2">
                  <button
                    class="btn btn-sm btn-outline-primary rounded-circle fs-7 m-1"
                    type="button"
                    @click="openModal('showModifyModal', item)"
                  >
                    <i class="bi bi-pencil-fill" style="top: -3px"></i>
                  </button>
                  <button
                    v-if="!item.isPublic"
                    class="btn btn-sm btn-outline-danger rounded-circle fs-7 m-1"
                    type="button"
                    @click="openModal('showDelModal', item)"
                  >
                    <i class="bi bi-trash" style="top: -3px"></i>
                  </button>
                </div>
              </div>

              <img
                :src="$imageFilters(item.imagePath)"
                class="card-img-top obj-fit-cover"
                :alt="item.alternativeText"
                style="height: 120px"
              />
              <div class="card-body">
                <h6 class="card-title mb-2 text-primary fw-bold">{{ item.alternativeText }}</h6>
                <p class="fs-8">{{ item.memo || "無圖片說明" }}</p>
                <p class="card-text text-info fs-9">
                  網址:
                  <a :href="item.redirectURL" target="_blank">
                    {{ item.redirectURL || "-" }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </template>
        <p v-else class="py-4">目前無資料</p>
      </div>
    </div>

    <Pagination :pages="pages" @changePage="fireQueryBanners" v-if="pages.totalPages > 0" />

    <AppModal
      v-model="showModifyModal"
      refName="BannerModal"
      :title="modalData.bannerId ? '編輯首頁輪播圖片' : '新增首頁輪播圖片'"
    >
      <BannerModal
        :modal-data="modalData"
        @finishContent="finishContent('showModifyModal')"
        @cancel="showModifyModal = false"
      />
    </AppModal>

    <AppModal v-model="showDelModal" refName="questionModal" title="刪除輪播圖片">
      <QuestionModal
        :content="`確認刪除-${modalData.memo} ?`"
        @checkInfo="deleteBanner(modalData.bannerId)"
      />
    </AppModal>
  </div>
</template>

<script>
import { apiDeleteBanner, apiQueryAdminBanner } from "@/api/banner";
import BannerModal from "@/components/BannerModal.vue";
import Pagination from "@/components/Pagination.vue";
import QuestionModal from "@/components/QuestionModal.vue";
import AppSearch from "@/components/AppSearch.vue";
import AppModal from "@/components/AppModal.vue";

export default {
  components: {
    AppModal,
    AppSearch,
    BannerModal,
    QuestionModal,
    Pagination,
  },
  data() {
    return {
      banners: [],
      modalData: {},
      showModifyModal: false,
      showDelModal: false,
      pages: {
        totalPages: 1,
        currentPage: 1,
      },
      publicTypes: [
        { label: "全部", value: "" },
        { label: "已公開", value: true },
        { label: "未公開", value: false },
      ],
      searchItems: {
        memo: "",
        isPublic: "",
      },
      tempMemo: "",
    };
  },
  computed: {
    publicTypeName() {
      return this.publicTypes.find((type) => type.value === this.searchItems.isPublic)?.label;
    },
  },
  methods: {
    async queryBanners({ page, memo, isPublic } = {}) {
      const { memo: memoSearchItems, isPublic: isPublicSearchItems } = this.searchItems;
      const searchParams = {
        isPublic: isPublic || isPublicSearchItems,
        memo: memo || memoSearchItems,
        page: page || this.pages.currentPage,
      };
      this.$vLoading(true);
      try {
        const res = await apiQueryAdminBanner(searchParams);
        const { code, data, message } = res.data;
        if (code === 200) {
          const { contents, pageInfo } = data;
          const { totalPages, currentPage } = pageInfo;
          this.banners = contents;
          this.pages = { totalPages, currentPage };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    async deleteBanner(id) {
      this.$vLoading(true);
      try {
        await apiDeleteBanner(id);
        this.queryBanners();
        this.modalData = {};
        this.showDelModal = false;
      } catch (error) {
        // regardless
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    finishContent(modalName) {
      this[modalName] = false;
      this.modalData = {};
      this.queryBanners();
    },
    openModal(modalName, item) {
      this.modalData = item || {};
      this[modalName] = true;
    },
    handleMemoSearch() {
      this.searchItems.memo = this.tempMemo;
      this.tempMemo = "";
      this.fireQueryBanners(1);
    },
    fireQueryBanners(page) {
      this.pages.currentPage = page;
      this.queryBanners();
    },
    querySearch(val) {
      this.tempMemo = val;
      this.pages.currentPage = 1;
      this.handleMemoSearch();
    },
  },
  watch: {
    "searchItems.isPublic"() {
      this.queryBanners();
    },
  },
  created() {
    this.queryBanners();
  },
};
</script>
