<template>
  <div>
    <div class="modal-body px-5 py-5">
      {{ content }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm text-white px-4" @click="check">
        確認
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  emits: ["checkInfo"],
  methods: {
    check() {
      this.$emit("checkInfo");
    },
  },
};
</script>
