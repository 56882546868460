<template>
  <VForm @submit="createUpdateContent" ref="form" v-slot="{ errors }">
    <div class="modal-body p-4">
      <div class="row mb-3 align-items-center">
        <div class="col-md-7">
          <div
            class="w-100 bg-light banner-image mb-2"
            :class="banner.imagePath ? 'show' : ''"
            style="height: 200px"
          >
            <img
              v-if="banner.imagePath"
              class="rounded-start w-100"
              :src="$imageFilters(banner.imagePath)"
              alt="圖片"
            />
          </div>
        </div>
        <div class="col-md-5">
          <AppUpload @uploadSuccess="uploadSuccess" />

          <label for="alternativeText" class="form-label">圖片名稱*</label>
          <div class="input-group">
            <VField
              id="alternativeText"
              name="圖片縮圖文字"
              placeholder="圖片縮圖文字"
              type="text"
              :class="{ 'is-invalid': errors['圖片縮圖文字'] }"
              class="form-control border"
              maxlength="10"
              v-model.trim="banner.alternativeText"
              rules="required|max:10"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ banner.alternativeText?.length }}/10</span
            >
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-3">
          <label for="title" class="form-label">圖片說明</label>
          <div class="input-group">
            <VField
              id="title"
              name="圖片說明"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['圖片說明'] }"
              placeholder="輸入圖片說明"
              v-model="banner.memo"
              maxlength="50"
              rules="max:50"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ banner.memo?.length }}/50</span
            >
          </div>
        </div>
        <div class="mb-3">
          <label for="redirectPath" class="form-label">網址</label>
          <div class="input-group">
            <VField
              id="redirectPath"
              name="網址"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['網址'] }"
              placeholder="輸入網址"
              v-model="banner.redirectURL"
              maxlength="200"
              rules="url|max:200"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ banner.redirectURL?.length }}/200</span
            >
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <label class="form-label m-0" for="article-active">是否公開</label>
          <div class="switch-group ms-2">
            <input type="checkbox" id="article-active" role="button" v-model="banner.isPublic" />
            <div class="ico_switch"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" @click="$emit('cancel')">取消</button>
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="Object.keys(bannerData).length === 0"
      >
        儲存
      </button>
    </div>
  </VForm>
</template>

<script>
import { apiCreateBanner, apiUpdateBanner } from "@/api/banner";

export default {
  props: ["modalData"],
  inject: ["emitter"],
  data() {
    return {
      banner: {
        memo: "",
        isPublic: false,
        redirectURL: "",
        alternativeText: "",
        imageId: "",
        imagePath: "",
      },
    };
  },
  computed: {
    bannerData() {
      const bannerItems = ["redirectURL", "imageId", "memo", "isPublic"];
      const data = bannerItems.reduce((acc, item) => {
        return Object.is(this.banner[item], this.modalData[item])
          ? acc
          : { ...acc, [item]: this.banner[item] };
      }, {});
      return data.imageId ? { ...data, alternativeText: this.banner.alternativeText } : data;
    },
  },
  methods: {
    async createUpdateContent() {
      if (!this.banner.imageId) {
        this.emitter.emit("notice-message", { style: "warning", title: "請上傳圖片" });
        return;
      }
      try {
        this.$vLoading(true);
        const { bannerId } = this.banner;
        const res = bannerId
          ? await apiUpdateBanner(bannerId, this.bannerData)
          : await apiCreateBanner(this.bannerData);
        const { code } = res.data;
        if (code === 200) {
          this.$vHttpsNotice({
            statusCode: res.status,
            title: `${bannerId ? "編輯" : "新增"}輪播圖片`,
          });
          this.$emit("finishContent");
        } else {
          this.$vErrorNotice();
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    uploadSuccess(item) {
      const { imageId, imagePath } = item;
      this.banner.imagePath = imagePath;
      this.banner.imageId = imageId;
    },
    init() {
      this.banner = {
        memo: "",
        isPublic: false,
        redirectURL: "",
        alternativeText: "",
        imageId: "",
        imagePath: "",
      };
    },
  },
  watch: {
    modalData(val) {
      if (val.bannerId) {
        this.banner = { ...val };
      } else {
        this.$refs.form.resetForm();
        this.init();
      }
    },
  },
};
</script>

<style lang="scss">
.banner-image {
  height: 200px;
  img {
    height: 100%;
    object-fit: contain;
  }
  &::after {
    content: "請上傳圖片*";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #39698d;
  }
  &.show {
    &::after {
      content: "";
    }
  }
}
</style>
