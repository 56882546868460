import APIService from "../common/https";
// Banner
//
// 後台
// @desc   banner-前台列表
// @access Private
export const apiQueryAdminBanner = (params) =>
  APIService.query("banner/backstage", params, { tokenRequired: true });

export const apiCreateBanner = (data) => APIService.create("banner", data);
export const apiUpdateBanner = (id, data) => APIService.update("banner", id, data);
export const apiDeleteBanner = (id) => APIService.delete("banner", id);

// @desc   banner-前台列表
// @access Public
export const apiQueryUserBanner = (params) => APIService.query("banner", params);
